@tailwind base;
@tailwind components;
@tailwind utilities;

.js-interaction {
  cursor: pointer;
}
select,
input {
  border: none;
}
.checkbox {
  border-color: #394862 !important;
}
.target-select:checked + span,
.target-select + span + span {
  display: none;
}
.target-select + span,
.target-select:checked + span + span {
  display: inline;
}
.target-list:checked + label,
.templates-list:checked + label {
  border: 1px solid #394862 !important;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.radio-input:checked + label .radio-custom,
.target-list:checked + label .radio,
.templates-list:checked + label .radio,
.schedule-send:checked + label .radio,
input:checked + label .radio {
  background: radial-gradient(
    circle,
    #394862 0%,
    #394862 35%,
    transparent 35%,
    transparent 100%
  );
  border-color: #394862 !important;
}
.checkbox-input:checked + label .checkbox-custom,
.domain-type:checked + label .checkbox,
.template-send:checked + label .checkbox,
.target-pixel:checked + label .checkbox,
.target-pixel:checked + label .checkbox,
.testingChannel:checked + .checkbox {
  background: radial-gradient(
    circle,
    #394862 0%,
    #394862 35%,
    transparent 35%,
    transparent 100%
  );
  border-color: #394862 !important;
}
.focus-reset:focus {
  box-shadow: none !important;
}
.custom-select {
  appearance: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='m7 10 5 5 5-5-1 0-4 4-4-4-1 0z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  padding-right: 2rem !important;
  border-radius: 0;
}
.custom-select:focus {
  outline: none;
}
.custom-select.server {
  background-position-y: calc(12px - 50%);
}
.max-w-fullscreen {
  max-width: 100vw !important;
}
.blur-on-edit > * {
  filter: blur(5px);
  transition: filter 300ms ease-out;
}
.loading {
  display: flex;
  align-self: center;
  margin: auto;
  margin-top: 20px;
  width: 30px;
  height: 30px;
  border: 3px solid rgba(57, 72, 98, 0.3);
  border-radius: 50%;
  border-top-color: #394862;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}
.media-library .btn-close {
  filter: invert(1);
  opacity: 1;
}
.no-shadow {
  box-shadow: none !important;
}
.modal-content {
  border: none;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.shadow-email-content-tabs {
  box-shadow: 1em 0rem 1rem rgba(0, 0, 0, 0.05) !important;
}
.media-scroll-wrap::before,
.media-scroll-wrap::after {
  content: "";
  position: sticky;
  width: 100%;
  display: block;
  height: 20px;
}
.media-scroll-wrap > div {
  margin-top: -20px;
  margin-bottom: -20px;
}
.media-scroll-wrap::before {
  top: 0;
  background: linear-gradient(0, transparent, #fff);
}
.media-scroll-wrap::after {
  bottom: 0;
  background: linear-gradient(0, #fff, transparent);
}
.email-content img {
  max-width: 100%;
}
.text-wrap {
  overflow-wrap: anywhere;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@media (prefers-color-scheme: dark) {
  body {
    background-color: #11151d;
  }
  .checkbox {
    border-color: #fff !important;
  }
  .target-list:checked + label,
  .templates-list:checked + label {
    border: 1px solid #fff !important;
  }
  input:checked + label .radio,
  .target-list:checked + label .radio,
  .templates-list:checked + label .radio,
  .schedule-send:checked + label .radio {
    background: radial-gradient(
      circle,
      #fff 0%,
      #fff 35%,
      transparent 35%,
      transparent 98%,
      #fff 99%,
      #fff 100%
    ) !important;
    border: 1px solid #fff !important;
  }
  .domain-type:checked + label .checkbox,
  .template-send:checked + label .checkbox,
  .target-pixel:checked + label .checkbox {
    background: radial-gradient(
      circle,
      #fff 0%,
      #fff 35%,
      transparent 35%,
      transparent 100%
    );
    border-color: #fff !important;
  }
}
